import React from "react";

const Hero = () => {
  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row">
          <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
            <div className="max-w-xl mb-6">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider uppercase bg-teal-accent-400 text-teal-600 rounded-full">
                  New Release
                </p>
              </div>

              <div className="flex flex-col items-start mb-6 ">
                <img
                  src="app_icon.png"
                  alt="App Icon"
                  className="w-8/12 sm:w-8/12 lg:w-10/12"
                />

                <div className="">
                  {" "}
                  <h6 className="font-sans text-base font-medium text-white sm:text-lg ">
                    IQ Riddles{" "}
                    <span className="inline-block text-deep-purple-accent-400">
                      & Puzzles
                    </span>
                  </h6>
                </div>
              </div>

              <br className="hidden md:block" />
              <p className="text-[#e3e3e3] text-base md:text-lg">
                Discover the thrill of challenging your brain with engaging
                riddles and puzzles that push your IQ to the next level. Solve
                complex problems, uncover hidden patterns, and have fun while
                learning!
              </p>
            </div>
            <div className="flex items-center space-x-3">
              {/* <a
                href="/"
                className="w-32 transition duration-300 hover:shadow-lg"
              >
                <img
                  src=""
                  className="object-cover object-top w-full h-auto mx-auto"
                  alt=""
                />
              </a> */}
              <a
                href="/"
                className="w-32 transition duration-300 hover:shadow-lg"
              >
                <img
                  src="google_play.png"
                  className="object-cover object-top w-full h-auto mx-auto"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/2">
            <div className="w-2/5">
              <img className="object-cover" src="6.png" alt="" />
            </div>
            <div className="w-5/12 -ml-16 lg:-ml-32">
              <img className="object-cover" src="2.png" alt="" />
            </div>
          </div>
        </div>
        <a
          href="#features"
          aria-label="Scroll down"
          className="flex items-center justify-center w-10 h-10 mx-auto text-gray-600 hover:text-deep-purple-accent-400 hover:border-deep-purple-accent-400 duration-300 transform border border-gray-400 rounded-full hover:shadow hover:scale-110"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="currentColor"
          >
            <path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z" />
          </svg>
        </a>
      </div>
    </>
  );
};

export default Hero;

import React from "react";

const GameShowcase = () => {
  return (
    <>
      <section id="features" className="py-16 text-gray-200">
        <div className="container mx-auto text-center">
          {/* <!-- Introduction --> */}
          <h2 className="text-3xl font-bold mb-6">Why You Should Play</h2>

          {/* <!-- Benefits --> */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
            <div className="p-4">
              <h3 className="text-2xl font-semibold mb-4 text-gray-200">
                Enhance Cognitive Skills
              </h3>
              <p className="text-lg text-gray-400">
                Solving puzzles regularly helps improve problem-solving
                abilities, logical thinking, and cognitive skills. It keeps your
                mind active and sharp.
              </p>
            </div>
            <div className="p-4">
              <h3 className="text-2xl font-semibold mb-4 text-gray-200">
                Stress Relief
              </h3>
              <p className="text-lg text-gray-400">
                Engaging in puzzles can be a relaxing way to unwind. It provides
                a productive distraction and can help reduce stress levels.
              </p>
            </div>
            <div className="p-4">
              <h3 className="text-2xl font-semibold mb-4 text-gray-200">
                Boosts Mathematical Skills
              </h3>
              <p className="text-lg text-gray-400">
                Our puzzles are designed to enhance your mathematical
                understanding and skills, making learning math enjoyable and
                effective.
              </p>
            </div>
          </div>

          <section className="py-16">
            {/* <!-- Inbuilt Features --> */}
            <h2 className="text-3xl font-bold mb-8">Features</h2>
            <div className="container mx-auto">
              {/* Inbuilt Calculator Section */}
              <div className="flex flex-col sm:flex-row items-center gap-8 mb-12">
                <div className="w-full sm:w-1/2 p-4 flex justify-center sm:order-last">
                  <img
                    src="5.png"
                    alt="Inbuilt Calculator"
                    className="object-cover max-w-36 h-auto rounded-lg"
                  />
                </div>
                <div className="w-full sm:w-1/2 p-4">
                  <h3 className="text-2xl font-semibold mb-4 text-left text-gray-200">
                    Calculator
                  </h3>
                  <p className="text-left text-lg text-gray-400">
                    Quickly solve complex equations directly within the game. No
                    need to switch apps - everything you need is right at your
                    fingertips.
                  </p>
                </div>
              </div>

              {/* Sketch Option Section */}
              <div className="flex flex-col sm:flex-row items-center gap-8">
                <div className="w-full sm:w-1/2 p-4 flex justify-center sm:order-first">
                  <img
                    src="6.png"
                    alt="Sketch Option"
                    className="object-cover max-w-36 h-auto rounded-lg"
                  />
                </div>
                <div className="w-full sm:w-1/2 p-4">
                  <h3 className="text-2xl font-semibold mb-4 text-left text-gray-200">
                    Sketch
                  </h3>
                  <p className="text-left text-lg text-gray-400">
                    Easily sketch out your solutions or note down your thoughts
                    with our intuitive sketching feature. It's designed to make
                    solving puzzles more interactive and convenient.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <h2 className="text-3xl font-bold mb-6">Gameplay</h2>
          <div className="p-4">
            <h3 className="text-lg mb-4 text-gray-400 text-center">
              Explore a vast array of puzzles with 750 levels and more being
              added. Each challenge is designed to keep you engaged and
              entertained.
            </h3>
          </div>
        </div>
      </section>
    </>
  );
};

export default GameShowcase;

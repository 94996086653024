import React from "react";

const About = () => {
  return (
    <>
      <section className="pb-16 text-gray-200">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">About Apptoise</h2>
          <div className="p-4">
            <p className="text-lg mb-4 text-gray-400">
              Welcome to Apptoise! Our debut game marks the start of our journey
              to deliver engaging and valuable experiences. With a commitment to
              quality and innovation, we strive to create apps and games that
              are both useful and fun. This is just the beginning - stay tuned
              for more exciting releases from Apptoise. We can't wait to share
              what's coming next.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";

const App = () => (
  <Router>
    <Routes>
      {/* Route for the Home Page */}
      <Route path="/" element={<Home />} />
      {/* Route for the Privacy Policy Page */}
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);

export default App;

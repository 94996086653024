import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header className="flex justify-center items-center h-16">
        <Link to="/" className="flex items-center space-x-2 h-24 w-36">
          <img src="apptoise.svg" alt="" />
        </Link>
      </header>
    </>
  );
};

export default Header;

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="contact" className="py-4  text-center">
      <p className="text-gray-400">
        <a href="mailto:apptoise@gmail.com" className="mx-4">
          Contact
        </a>
        <Link to="/privacy-policy" className="mx-4">
          Privacy Policy
        </Link>
      </p>
      <p className="text-gray-400 mt-2">
        &copy; {new Date().getFullYear()} APPTOISE. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;

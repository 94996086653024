import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import GameShowcase from "../components/GameShowcase";
import Footer from "../components/Footer";
import About from "../components/About";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <GameShowcase />
      <About />
      <Footer />
    </>
  );
};

export default Home;
